import _ from "lodash";
import moment from "moment";
import { set, isThisMonth, format, startOfMonth, endOfMonth } from "date-fns";
import { useState } from "react";
import { formatInTimeZone } from "date-fns-tz";
import toast from "react-hot-toast";
import { toPng } from "html-to-image";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  breakDownDefaultOptions,
  eventDefaultOptions,
} from "../Pages/Insights/Reports/Dropdowns/data";

export const DATE_FORMAT = "yyyy-MM-DD";

export function findLabelOption(options, payload) {
  for (const option of options) {
    const viewsOption = option.options.find((opt) => opt.value === payload);
    if (viewsOption) {
      return viewsOption;
    }
  }
  return null;
}

//open Intercom chat on click
export const openChat = () => {
  window.Intercom("show");
};

//format values
export const valueFormat = (data) => {
  if (data === "NaN") return "";
  if (data) {
    return _.isInteger(data) ? data : parseFloat(data).toFixed(2);
  } else {
    return 0;
  }
};

//title case
export const titleCase = (str, type = null) => {
  str = str.toString();
  if (type && type.toLowerCase() === "meta_continent_code")
    return str.toUpperCase();
  if (type && type.toLowerCase() === "meta_region") return str.toUpperCase();
  if (type && type.toLowerCase() === "custom_user_id") return str;
  if (type && type.toLowerCase() === "user email") return str;
  if (type && type.toLowerCase() === "video source hostname")
    return str.toLowerCase();
  if (str.toLowerCase().startsWith("com.")) return str.toLowerCase();
  if (str.toLowerCase().startsWith("https")) return str;
  if (str.toLowerCase().startsWith("http")) return str;

  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const convertMillisecondsToHours = (ms) => {
  const hours = ms / (1000 * 60 * 60);
  return hours;
};

//millis conversion
export const millisToMinutesAndSeconds = (millis) => {
  if (millis === undefined) {
    return "-";
  }

  var seconds = Math.floor((millis / 1000) % 60);
  var minutes = Math.floor((millis / (1000 * 60)) % 60);
  var hours = Math.floor(millis / (1000 * 60 * 60));

  hours = hours > 0 ? hours + " hr " : "";
  minutes = minutes > 0 ? minutes + " min " : "";
  seconds = seconds > 0 && !(hours && minutes) ? seconds + " sec " : "";

  return hours || minutes || seconds ? hours + minutes + seconds : "-";
};

//make first letter capital
export const capitalizeFirstLetter = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

//convert degree to dms (for lat and long)
export const ConvertDEGToDMS = (deg, lat) => {
  var absolute = Math.abs(deg);

  var degrees = Math.floor(absolute);
  var minutesNotTruncated = (absolute - degrees) * 60;
  var minutes = Math.floor(minutesNotTruncated);
  var seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2);

  var direction;

  if (lat) {
    direction = deg >= 0 ? "N" : "S";
  } else {
    direction = deg >= 0 ? "E" : "W";
  }

  return degrees + "°" + minutes + "'" + seconds + '"' + direction;
};

//char limit
export const charLimit = (str) => {
  if (str.length > 40) {
    return (
      str.substr(0, 20) + "....." + str.substr(str.length - 10, str.length)
    );
  }
  return str;
};
export const titleLimit = (str) => {
  if (str.length > 40) {
    return str.substr(0, 30) + "...";
  }
  return str;
};

export const formatBytes1000 = (bytes, decimals) => {
  if (!bytes || bytes === 0) return "0 Byte";
  var k = 1000; // or 1000 for binary
  var dm = decimals + 1 || 3;
  var sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  var i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const formatBytes = (bytes, decimals) => {
  if (!bytes || bytes === 0) return "0 Byte";
  var k = 1000; // or 1000 for binary
  var dm = decimals + 1 || 3;
  var i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
};

export const getMonthYearFromCreated = (created) => {
  let result = moment(created).format("yyyy-MM-DD");
  // gives month year in descending order since created date
  created = new Date(result);
  let currentDate = new Date();
  var months = [];
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  var year = created.getFullYear();
  var month = created.getMonth();
  do {
    do {
      months.push(monthNames[month] + " " + year);
      month++;
    } while (
      (year !== currentDate.getFullYear() && month < 12) ||
      month <= currentDate.getMonth()
    );
    month = 0;
    year++;
  } while (year <= currentDate.getFullYear());
  return months.reverse();
};

export const getMonthYearFromCurrentDate = () => {
  let currentDate = new Date();
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = currentDate.getFullYear();
  var month = currentDate.getMonth();
  return monthNames[month] + " " + year;
};

export const prepareQuery = async (options, req, top_assets_list) => {
  // Initialize date_range
  options["date_range"] = {};

  // Prioritize custom date range if provided and valid
  if (req.custom && req.custom.length === 2) {
    options.date_range["start_at"] = format(req?.custom[0], "yyyy-MM-dd");
    options.date_range["end_at"] = format(req?.custom[1], "yyyy-MM-dd");
  }
  // Use monthYear if custom date range is not provided
  else if (req.monthYear) {
    options.date_range["start_at"] = moment(req.monthYear.value[0]).format(
      "YYYY-MM-DD"
    );

    options.date_range["end_at"] = moment(req.monthYear.value[1]).format(
      "YYYY-MM-DD"
    );
  }

  // else if (req.monthYear) {
  //   let months = [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ];
  //   let monthDate = set(new Date(), {
  //     month: months.indexOf(req.monthYear.split(" ")[0]),
  //     year: req.monthYear.split(" ")[1],
  //   });
  //   if (isThisMonth(monthDate)) {
  //     options.date_range["start_at"] = format(
  //       startOfMonth(new Date()),
  //       "yyyy-MM-dd"
  //     );
  //     options.date_range["end_at"] = format(new Date(), "yyyy-MM-dd");
  //   } else {
  //     options.date_range["start_at"] = format(
  //       startOfMonth(monthDate),
  //       "yyyy-MM-dd"
  //     );
  //     options.date_range["end_at"] = format(
  //       endOfMonth(monthDate),
  //       "yyyy-MM-dd"
  //     );
  //   }
  // }

  // If source is selected
  if (req.source && req.source !== "all") {
    options["filters"] = {};
    options.filters["source_id"] = req.source;
  }

  // If top_assets_list is provided
  if (top_assets_list) {
    options["top_assets_count"] = 100;
  }

  return options;
};

// export const prepareQuery = async (options, req, top_assets_list) => {
//   console.log(req);
//   //if monthYear is selected
//   options["date_range"] = {};
//   if (req.monthYear) {
//     let months = [
//       "Jan",
//       "Feb",
//       "Mar",
//       "Apr",
//       "May",
//       "Jun",
//       "Jul",
//       "Aug",
//       "Sep",
//       "Oct",
//       "Nov",
//       "Dec",
//     ];
//     let monthDate = set(new Date(), {
//       month: months.indexOf(req.monthYear.split(" ")[0]),
//       year: req.monthYear.split(" ")[1],
//     });
//     if (isThisMonth(monthDate)) {
//       options.date_range["start_at"] = format(
//         startOfMonth(new Date()),
//         "yyyy-MM-dd"
//       );
//       options.date_range["end_at"] = format(new Date(), "yyyy-MM-dd");
//     } else {
//       options.date_range["start_at"] = format(
//         startOfMonth(monthDate),
//         "yyyy-MM-dd"
//       );
//       options.date_range["end_at"] = format(
//         endOfMonth(monthDate),
//         "yyyy-MM-dd"
//       );
//     }
//   } else if (!req.monthYear) {
//     options.date_range["start_at"] = format(
//       startOfMonth(new Date()),
//       "yyyy-MM-dd"
//     );
//     options.date_range["end_at"] = format(new Date(), "yyyy-MM-dd");
//   }
//   // if source is selected
//   if (req.source && req.source !== "all") {
//     options["filters"] = {};
//     options.filters["source_id"] = req.source;
//   }

//   if (top_assets_list) {
//     options["top_assets_count"] = 100;
//   }

//   return options;
// };

export const formatNumbers = (number) => {
  // Billions
  return Math.abs(Number(number)) >= 1.0e9
    ? (Math.abs(Number(number)) / 1.0e9).toFixed(2) + "B"
    : // Millions
    Math.abs(Number(number)) >= 1.0e6
    ? (Math.abs(Number(number)) / 1.0e6).toFixed(2) + "M"
    : // Thousands
    Math.abs(Number(number)) >= 1.0e3
    ? (Math.abs(Number(number)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(number));
};

export const duration8601 = (seconds_duration) => {
  let hour = parseInt(seconds_duration / 3600);
  let minute = parseInt((seconds_duration - hour * 3600) / 60);
  let seconds = seconds_duration - (hour * 3600 + minute * 60);
  let ret = "PT";
  if (hour) ret += hour + "H";
  if (minute) ret += minute + "M";
  if ((!hour && !minute) || seconds) ret += seconds + "S";
  return ret;
};

export const formatDuration = (number, video_cms) => {
  let d = Number(number);
  var s = Math.floor(d % 60);
  var h = Math.floor(d / 3600);
  var m = video_cms ? Math.floor((d % 3600) / 60) : Math.ceil((d % 3600) / 60);

  var seconds = s >= 0 && s < 10 ? "0" + s : s >= 10 ? s : 0;
  var minutes = m >= 0 && m < 10 ? "0" + m : m >= 10 ? m : 0;
  var hours = h > 0 ? formatNumbers(h) : 0;

  return {
    original: Math.ceil(Number(number) / 60), //sending total minutes for tooltip.
    hour: hours,
    minute: minutes,
    second: seconds,
  };
};

export const showFilterCount = (paginationDetails) => {
  //make copy of paginationDetails
  let p1 = {
    ...paginationDetails,
  };

  //delete unused object keys
  delete p1["page"];
  delete p1["limit"];
  delete p1["title"];
  delete p1["sortBy"];
  delete p1["orderBy"];
  delete p1["playlist"];

  //create and push objects in array
  var newData = Object.keys(p1).map((key) => ({
    key: key,
    value: p1[key],
  }));

  //remove object if it's value is empty
  var data = newData.filter((o) => o.value);

  return data;
};

export function convertAndCalculatePercentage(usedSize, totalSize) {
  if (usedSize && totalSize) {
    const units = {
      B: 1,
      KB: 1024,
      MB: 1024 ** 2,
      GB: 1024 ** 3,
      TB: 1024 ** 4,
      PB: 1024 ** 5,
    };

    const regexPattern = /(\d+(\.\d+)?)\s*([KMGTP]B?)?/g;

    // Find all matches in the input strings
    const usedMatches = usedSize.matchAll(regexPattern);
    const totalMatches = totalSize.matchAll(regexPattern);

    // Initialize variables for total and used bytes
    let totalBytes = 0;
    let usedBytes = 0;

    // Iterate over matches and calculate total bytes
    for (const match of totalMatches) {
      const [, value, , unit] = match;
      const floatValue = parseFloat(value);
      const byteSize = floatValue * (units[unit?.toUpperCase()] || 1);
      totalBytes += byteSize;
    }

    // Iterate over matches and calculate used bytes
    for (const match of usedMatches) {
      const [, value, , unit] = match;
      const floatValue = parseFloat(value);
      const byteSize = floatValue * (units[unit?.toUpperCase()] || 1);
      usedBytes += byteSize;
    }

    // Calculate percentage
    const percentage = (usedBytes / totalBytes) * 100 || 0;

    return Math.round(parseFloat(percentage));
  }
}

export const convertDataSize = (usedSize, totalSize) => {
  if (usedSize && totalSize) {
    const units = {
      B: 1,
      KB: 1024,
      MB: 1024 ** 2,
      GB: 1024 ** 3,
      TB: 1024 ** 4,
      PB: 1024 ** 5,
    };

    //this function extracts the numeric values and units using regular expressions.
    //It converts the sizes to bytes and then calculates the percentage by dividing the used bytes
    //by the total bytes and multiplying by 100.

    const sizePattern = /^(\d+(\.\d+)?)\s*(\w+)$/i;
    const [, usedValue, , usedUnit] = sizePattern.exec(usedSize);
    const [, totalValue, , totalUnit] = sizePattern.exec(totalSize);

    const usedBytes = parseFloat(usedValue) * units[usedUnit.toUpperCase()];
    const totalBytes = parseFloat(totalValue) * units[totalUnit.toUpperCase()];

    const percentage = (usedBytes / totalBytes) * 100;

    return percentage;
  }
};

export const calculateProgressBarWidth = (dataSize, quotaSize) => {
  var progress = (dataSize / quotaSize) * 100;
  return progress;
};

export const updateUrl = (
  paramsToUpdate,
  paramsToKeep,
  searchParams,
  setSearchParams
) => {
  // Set an additional parameter
  const updatedParams = {
    ...Object.fromEntries(searchParams.entries()),
    ...paramsToUpdate,
  };

  // Filter out the parameters to keep
  const filteredParams = Object.entries(updatedParams).reduce(
    (acc, [key, value]) => {
      if (paramsToKeep.includes(key)) {
        acc[key] = value;
      }
      return acc;
    },
    {}
  );

  // Update the URL with the filtered parameters and the new parameters
  setSearchParams(filteredParams);
};

export const setOrDeleteParam = (paramName, value, searchParams) => {
  if (value || (Array.isArray(value) && value.length > 0)) {
    searchParams.set(paramName, value);
  } else {
    searchParams.delete(paramName);
  }
};

export const useCommonState = (paginationParams, page_limit) => {
  const [paginationDetails, setPaginationDetails] = useState({
    page: paginationParams.page
      ? isNaN(paginationParams.page)
        ? 1
        : parseInt(paginationParams.page)
      : 1,
    limit:
      paginationParams.limit && !isNaN(paginationParams.limit)
        ? parseInt(paginationParams.limit)
        : page_limit && !isNaN(page_limit)
        ? parseInt(page_limit)
        : 10,
    title: paginationParams.title ? paginationParams.title : "",
    tag: paginationParams.tag ? paginationParams.tag : "",
    status: paginationParams.status ? paginationParams.status : "",
    start_date: paginationParams.start_date ? paginationParams.start_date : "",
    end_date: paginationParams.end_date ? paginationParams.end_date : "",
    min_duration: paginationParams.min_duration
      ? paginationParams.min_duration
      : "",
    max_duration: paginationParams.max_duration
      ? paginationParams.max_duration
      : "",
    sortBy: paginationParams?.sortBy ? paginationParams.sortBy : "",
    orderBy: paginationParams?.orderBy ? paginationParams.orderBy : "",
    playlist: paginationParams?.playlist ? paginationParams.playlist : "",
  });

  return { paginationDetails, setPaginationDetails };
};

export const onErrorToastMessage = (err) => {
  return `${
    err?.response?.data?.error?.message +
    " :- " +
    err?.response?.data?.error?.param
  }`;
};

export const datePickerRange = [
  {
    label: "Today",
    value: [moment(), moment()],
    tooltip: moment().format("MMMM D, YYYY"),
  },
  {
    label: "Yesterday",
    value: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    tooltip: moment().subtract(1, "days").format("MMMM D, YYYY"),
  },
  {
    label: "7D",
    value: [moment().subtract(7, "days"), moment()],
    tooltip:
      moment().subtract(7, "days").format("MMMM D, YYYY") +
      " - " +
      moment().format("MMMM D, YYYY"),
  },
  {
    label: "30D",
    value: [moment().subtract(30, "days"), moment()],
    tooltip:
      moment().subtract(30, "days").format("MMMM D, YYYY") +
      " - " +
      moment().format("MMMM D, YYYY"),
  },
];

export const TOOLTIP_DATE_FORMAT = "MMMM D, YYYY";

export const datePickerRangesForReport = [
  {
    label: "Today",
    value: [moment(), moment()],
    custom_label: "today",
    tooltip: moment().format(TOOLTIP_DATE_FORMAT),
  },
  {
    label: "Yesterday",
    value: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    custom_label: "yesterday",
    tooltip: moment().subtract(1, "days").format(TOOLTIP_DATE_FORMAT),
  },
  {
    label: "7D",
    value: [moment().subtract(7, "days"), moment()],
    custom_label: "last_seven_days",
    tooltip:
      moment().subtract(7, "days").format(TOOLTIP_DATE_FORMAT) +
      " - " +
      moment().format(TOOLTIP_DATE_FORMAT),
  },
  {
    label: "30D",
    value: [moment().subtract(30, "days"), moment()],
    custom_label: "last_thirty_days",
    tooltip:
      moment().subtract(30, "days").format(TOOLTIP_DATE_FORMAT) +
      " - " +
      moment().format(TOOLTIP_DATE_FORMAT),
  },
];

export const datePickerRangesForUsageAnalytics = [
  {
    label: "This Month",
    value: [moment().startOf("month"), moment().endOf("month")],
    custom_label: "this_month",
  },
  {
    label: "Last Month",
    value: [
      moment().subtract(1, "months").startOf("month"),
      moment().subtract(1, "months").endOf("month"),
    ],
    custom_label: "last_month",
  },
];

export const truncateUrl = (url, maxLength) => {
  if (url.length > maxLength) {
    return url.substring(0, maxLength - 3) + "...";
  } else {
    return url;
  }
};

export const getMetricTooltip = (metric) => {
  let tooltip = "";

  switch (metric) {
    case "views":
      tooltip = "Total Views";
      break;
    case "unique_views":
      tooltip = "Unique Users";
      break;
    case "playing_time":
      tooltip = "Playing Time (hours)";
      break;
    case "concurrent_users":
      tooltip = "Max Concurrent Users";
      break;
    case "player_startup_time":
      tooltip = "Player Startup Time (ms)";
      break;
    case "startup_time":
      tooltip = "Video Startup Time (ms)";
      break;
    case "seek_latency":
      tooltip = "Seek Latency (ms)";
      break;
    case "rebuffer_percentage":
      tooltip = "Rebuffer Percentage (%)";
      break;
    case "rebuffer_duration":
      tooltip = "Rebuffer Duration (ms)";
      break;
    case "rebuffer_count":
      tooltip = "Rebuffer Count (rebuffers)";
      break;
    case "rebuffer_frequency":
      tooltip = "Rebuffer Frequency (rebuffers per minute)";
      break;
    case "playback_rate":
      tooltip = "Playback Rate (%)";
      break;
    case "playback_failure_percentage":
      tooltip = "Playback Failed (%)";
      break;
    case "upscale_percentage":
      tooltip = "Upscaling (%)";
      break;
    case "downscale_percentage":
      tooltip = "Bandwidth Wastage (%)";
      break;
    case "average_bitrate":
      tooltip = "Average Bitrate (mbps)";
      break;
    default:
      // Handle the case where metric does not match any known tooltip
      tooltip = "Unknown Metric";
      break;
  }

  return tooltip;
};

export const getDaterange = (timeframe) => {
  switch (timeframe) {
    case "today":
      return [moment().toDate(), moment().toDate()];
    case "yesterday":
      return [
        moment().subtract(1, "days").toDate(),
        moment().subtract(1, "days").toDate(),
      ];
    case "last_seven_days":
      return [moment().subtract(7, "days").toDate(), moment().toDate()];
    case "last_thirty_days":
      return [moment().subtract(30, "days").toDate(), moment().toDate()];
    case "this_month":
      return [
        moment().startOf("month").toDate(),
        moment().endOf("month").toDate(),
      ];
    case "last_month":
      return [
        moment().subtract(1, "month").startOf("month").toDate(),
        moment().subtract(1, "month").endOf("month").toDate(),
      ];
    default:
      return [moment().subtract(6, "days").toDate(), moment().toDate()];
  }
};

// this helper class to convert utc epoch to date with spcific timezone
export const convertEpochToTimezone = (epoch, timezone, inputFormat) => {
  if (!epoch) return "";

  // if the timezone is not provided use the default bvrowser timezone
  const format_date_pattern = inputFormat ? inputFormat : "dd LLL yyyy, p";

  if (!timezone) {
    return format(new Date(epoch), format_date_pattern);
  }

  return formatInTimeZone(new Date(epoch), timezone, format_date_pattern);
};

/* 
  This function is used to take a screenshot of the report graph and save it as a PNG file.
  It uses the html-to-image library to convert the report graph to a PNG data URL and then saves it as a file.
*/
export const handleScreenshot = (cardBodyRef, setExportReport, payload) => {
  if (cardBodyRef.current) {
    // Show loading toast
    const loadingToastId = toast.loading("Loading...");

    toPng(cardBodyRef.current)
      .then((dataUrl) => {
        saveAs(dataUrl, `${payload?.metrics[0]}.png`);
        // Dismiss loading toast
        toast.dismiss(loadingToastId);
        setExportReport(false);
        toast.success("Exported");
      })
      .catch((error) => {
        // Dismiss loading toast
        toast.dismiss(loadingToastId);
        setExportReport(false);
        toast.error(`Error: ${error.message}`);
      });
  }
};

/*
  This function is used to take a screenshot of the report graph and save it as a PDF file.
  It uses the html2canvas and jsPDF libraries to convert the report graph to a PDF file.
*/
export const handleSaveAsPdf = (saveGraphAsPDF, setExportReport, payload) => {
  if (saveGraphAsPDF.current) {
    // Show loading toast
    const loadingToastId = toast.loading("Exporting as PDF...");

    const getUpdatedMetrics = findLabelOption(
      eventDefaultOptions,
      payload.metrics[0]
    );
    const getDimension = findLabelOption(
      breakDownDefaultOptions,
      payload?.chart_dimension?.name
    );
    html2canvas(saveGraphAsPDF.current)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = pdfWidth;
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

        // Calculate the x and y coordinates for centering the image
        const x = (pdfWidth - imgWidth) / 2;
        const y = (pdfHeight - imgHeight) / 2;

        // Add text above the image
        const startDate = payload?.date_range?.start_at || "";
        const endDate = payload?.date_range?.end_at || "";
        const dimension = getDimension?.label || "";
        const title = `${getUpdatedMetrics?.label}${
          dimension ? ` by ${dimension}` : ""
        } :- ${startDate} - ${endDate}`;

        const textX = pdfWidth / 2;
        const textY = y - 10; // Position the text 10 units above the image
        pdf.setFontSize(16); // Set the font size
        pdf.text(title, textX, textY, { align: "center" });

        pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);
        pdf.save(`${payload?.metrics[0]}.pdf`);

        // Dismiss loading toast
        toast.dismiss(loadingToastId);
        setExportReport(false);
        toast.success("PDF exported");
      })
      .catch((error) => {
        // Dismiss loading toast
        toast.dismiss(loadingToastId);
        setExportReport(false);
        toast.error(`Error exporting as PDF: ${error.message}`);
      });
  }
};

export const exportTableToCSV = (mainStreamData, setExportReport, payload) => {
  const loadingToastId = toast.loading("Exporting data...");

  const generateCSV = (formattedData, xValues, yValues) => {
    let csvContent = "Title," + xValues.join(",") + "\n";
    yValues.forEach((item) => {
      let row = `${item}%`;
      xValues.forEach((date) => {
        const value = formattedData[date][item];
        const displayValue =
          value === 0
            ? value
            : value % 1 === 0
            ? value.toString()
            : value.toFixed(2);
        row += `,${displayValue}%`;
      });
      csvContent += row + "\n";
    });
    return csvContent;
  };

  if (
    mainStreamData &&
    mainStreamData.analytics_data &&
    mainStreamData?.analytics_data?.[payload.metrics[0]]?.$overall &&
    payload.metrics[0] !== "completion_percent_by_views" &&
    payload.metrics[0] !== "completion_percent"
  ) {
    const metricsKey = payload.metrics[0];
    const analyticsData = mainStreamData.analytics_data[metricsKey] || {};

    const keys = Object.keys(analyticsData).filter(
      (key) => key !== "has_next_page" && key !== "$overall"
    );

    const xValues = Array.isArray(analyticsData[keys[0]])
      ? analyticsData[keys[0]].map((item) => item.x)
      : [];

    const headers = [
      "Title",
      ...xValues.map((x) =>
        payload.group_by !== "hourly"
          ? moment(x).format("MMM D")
          : moment(x).utc().format("DD MMM\xa0\xa0\xa0LT")
      ),
    ];
    const rows = keys.map((key) => {
      const displayKey = key.trim() === "" ? "(not set)" : key;
      const rowData = analyticsData[key].map((item) =>
        _.isInteger(item.y) ? Math.round(item.y) : item.y
      );
      return [displayKey, ...rowData];
    });

    // Calculate totals for each column
    const columnTotals = xValues.map((x, index) => {
      const overallData = analyticsData["$overall"];
      if (overallData && overallData[index]) {
        return overallData[index].y;
      }
      return 0;
    });

    // Add totals row
    const totalsRow = [
      "Total",
      ...columnTotals.map((total) =>
        _.isInteger(total) ? Math.round(total) : total
      ),
    ];
    rows.push(totalsRow);

    let csvContent =
      "data:text/csv;charset=utf-8," +
      headers.join(",") +
      "\n" +
      rows.map((row) => row.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "breakdown_data.csv");
    document.body.appendChild(link);
    link.click();
  } else if (
    mainStreamData &&
    mainStreamData.analytics_data &&
    mainStreamData?.analytics_data?.[payload.metrics[0]] &&
    payload.metrics[0] !== "completion_percent_by_views" &&
    payload.metrics[0] !== "completion_percent"
  ) {
    const getUpdatedMetrics = findLabelOption(
      eventDefaultOptions,
      payload.metrics[0]
    );

    const metricsKey = mainStreamData?.analytics_data?.[payload.metrics[0]];
    const xValues = Array.isArray(metricsKey)
      ? metricsKey.map((item) => item.x)
      : [];

    const headers = [
      "Title",
      ...xValues.map((x) =>
        payload.group_by !== "hourly"
          ? moment(x).format("MMM D")
          : moment(x).utc().format("DD MMM\xa0\xa0\xa0LT")
      ),
    ];

    const rowData = [
      [
        getUpdatedMetrics ? getUpdatedMetrics.label : "",
        ...metricsKey.map((item) =>
          _.isInteger(item.y) ? Math.round(item.y) : item.y
        ),
      ],
    ];

    let csvContent =
      "data:text/csv;charset=utf-8," +
      headers.join(",") +
      "\n" +
      rowData.map((row) => row.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${getUpdatedMetrics.label}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  if (
    payload.metrics[0] === "completion_percent_by_views" ||
    payload.metrics[0] === "completion_percent"
  ) {
    const analyticsData =
      mainStreamData?.analytics_data?.[payload.metrics[0]] || {};
    const dataMap = {};
    for (const item of Object.values(analyticsData)) {
      const date = moment(item.columnid).format("MMM D");
      const range = item.rowid;

      if (!dataMap[date]) {
        dataMap[date] = {
          "90 - 100": null,
          "80 - 90": null,
          "70 - 80": null,
          "60 - 70": null,
          "50 - 60": null,
          "40 - 50": null,
          "30 - 40": null,
          "20 - 30": null,
          "10 - 20": null,
          "0 - 10": null,
        };
      }
      dataMap[date][range] = item.value;
    }

    const xValues = Object.keys(dataMap);
    const yValues = [
      "90 - 100",
      "80 - 90",
      "70 - 80",
      "60 - 70",
      "50 - 60",
      "40 - 50",
      "30 - 40",
      "20 - 30",
      "10 - 20",
      "0 - 10",
    ];

    const csvContent = generateCSV(dataMap, xValues, yValues);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "table_data.csv");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  setTimeout(() => {
    // Dismiss loading toast
    toast.dismiss(loadingToastId);

    setExportReport(false);
  }, 100);
};

export const colorCodes = [
  "#ff7f24",
  "#274e4b",
  "#31768d",
  "#8e7a9e",
  "#db6446",
  "#ff9e9e",
  "#8cbed6",
  "#ffa447",
  "#0f6c78",
  "#91b790",
];

export const isHourlyDisabled = (payload) => {
  const { start_at, end_at } = payload.date_range;
  const startDate = moment(start_at);
  const endDate = moment(end_at);
  const diffDays = endDate.diff(startDate, "days");
  return diffDays !== 0;
};
