import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Uppy from "@uppy/core";
import Cookies from "js-cookie";
import { Dashboard } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import Dropbox from "@uppy/dropbox";
import OneDrive from "@uppy/onedrive";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import toast from "react-hot-toast";
import { config } from "global/config";
import http from "utils/httpNew";
import { endpoints } from "global/endpoints";
import { customTrack } from "../../utils/constants";

export default function ProcessVideo({
  setOpenUppy,
  openUppy,
  theme,
  setAllPlaylistAssets,
  allPlaylistAssets,
  backdrop,
  setBackdrop,
  isUploadBtnClick,
  setIsUploadBtnClick,
  setSearchParams,
}) {
  const [open, setOpen] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const [totalFiles, setFiles] = useState([]);
  const [urlInputValue, setUrlInputValue] = useState("");
  const [isUploadOn, setIsUploadOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setOpen(true);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
    setIsHovered(false);
  };

  const searchParams = new URLSearchParams(window.location.search);
  const searchQueryPlaylist = searchParams.get("playlist");

  const url = new URL(window.location.href);
  // Get the 'source' query parameter from the URL
  const source = url.searchParams.get("source");
  const userToken = Cookies.get("user_token");
  const chevron_icon_class = `btn btn-ghost btn-icon rounded-circle btn-xs me-2 ${
    theme === "dark" ? "text-gray-900" : "text-white"
  }`;
  const close_icon_class = `btn btn-xs rounded-circle btn-icon ${
    theme === "dark"
      ? "bg-soft-uppy text-close-uppy"
      : "bg-soft-light text-white"
  }`;

  const uppy_config = useMemo(() => {
    return new Uppy({
      id: "uppy_config",
      debug: true,
      autoProceed: true,
      restrictions: {
        minNumberOfFiles: 1,
        allowedFileTypes: ["video/*", "application/octet-stream", "audio/*"],
      },
      allowMultipleUploadBatches: true,
      hideUploadButton: true,
    })
      .use(Dropbox, {
        companionUrl: "https://companion.gumlet.com",
      })

      .use(OneDrive, {
        companionUrl: "https://companion.gumlet.com",
      })
      .use(AwsS3Multipart, {
        limit: 10, // uploads 10 chunks in parallel
        getChunkSize(file) {
          return 1024 * 1024 * 10; // 10 MB chunk size
        },
        async createMultipartUpload(file) {
          let payload = JSON.stringify({
            filename: file.name,
            type: file.type,
            collection_id: source,
            title: file?.name?.substring(0, file?.name?.lastIndexOf(".")),
          });

          if (searchQueryPlaylist) {
            payload = JSON.stringify({
              ...JSON.parse(payload),
              playlist_id: searchQueryPlaylist,
            });
          }

          // STEP-1 create asset for each upload video requests
          const response = await fetch(
            config.API_URL_UPPY + "/v1/video/assets/upload",
            {
              method: "POST",
              // Send and receive JSON.
              headers: {
                accept: "application/json",
                "content-type": "application/json",
                Authorization: `Bearer ${userToken}`,
              },
              body: payload,
            }
          );

          if (!response.ok) {
            setIsUploadBtnClick(false);
            setOpenUppy(false);
            const error = await response.json();
            toast.error(error.error.message);
            return;
          }

          // Parse the JSON response.
          const data = await response.json();

          if (searchQueryPlaylist) {
            const newData = {
              ...allPlaylistAssets,
              all_assets: [...allPlaylistAssets.all_assets, data],
            };
            setAllPlaylistAssets(newData);
          }

          file.uploadId = data.asset_id;
          return {
            uploadId: data.asset_id,
            key: data.asset_id,
          };
        },

        async signPart(file, partData) {
          // STEP-2 Generate signed URL using partnumber of video ( Batch vise upload)
          const response = await fetch(
            `${config.API_URL_UPPY}/v1/video/assets/${partData.uploadId}/multipartupload/${partData.partNumber}/sign`,
            {
              headers: {
                accept: "application/json",
                "content-type": "application/json",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );

          let data = await response.json();

          // sending pre-signed url to S3 for uploading perticular multipart request
          return { url: data.part_upload_url };
        },

        async abortMultipartUpload(file, data) {
          const response = await fetch(
            `${config.API_URL_UPPY}/v1/video/assets/${data.uploadId}/multipartupload/abort`,
            {
              method: "POST",
              // Send and receive JSON.
              headers: {
                accept: "application/json",
                "content-type": "application/json",
                Authorization: `Bearer ${userToken}`,
              },
              body: JSON.stringify({}),
            }
          );
        },

        async listParts(file, data) {
          const response = await fetch(
            `${config.API_URL_UPPY}/v1/video/assets/${data.uploadId}/multipartupload/list`,
            {
              method: "POST",
              // Send and receive JSON.
              headers: {
                accept: "application/json",
                "content-type": "application/json",
                Authorization: `Bearer ${userToken}`,
              },
              body: JSON.stringify({}),
            }
          );

          const jsonResp = await response.json();
          return jsonResp.parts;
        },

        async completeMultipartUpload(file, data) {
          // STEP-3 Complete pre-signed URL checking
          let parts = data.parts;
          const response = await fetch(
            `${config.API_URL_UPPY}/v1/video/assets/${data.uploadId}/multipartupload/complete`,
            {
              method: "POST",
              // Send and receive JSON.
              headers: {
                accept: "application/json",
                "content-type": "application/json",
                Authorization: `Bearer ${userToken}`,
              },
              body: JSON.stringify({ parts }),
            }
          );
          return response;
        },
      })
      .on("file-added", (file) => {
        setBackdrop(false);
        // addes file to the state
        setFiles((prevFiles) => [...prevFiles, file]);
      })
      .on("complete", (result) => {
        result.successful.forEach(async (element) => {
          if (
            element.source === "Dropbox" ||
            // element.source === "GoogleDrive" ||
            element.source === "Url" ||
            element.source === "OneDrive"
          ) {
            let payload = JSON.stringify({
              input: element.uploadURL,
              collection_id: source,
              title: element.meta.name,
            });

            if (searchQueryPlaylist) {
              payload = JSON.stringify({
                ...JSON.parse(payload),
                playlist_id: searchQueryPlaylist,
              });
            }

            await http({})
              .post(endpoints.video.asset.createAsset, payload)
              .then((response) => {
                customTrack("Upload Source", {
                  source: element.source,
                });
              })
              .catch((error) => {
                toast.error(error?.response?.data?.error?.message);
              });
          }
          setOpenUppy(false);
        });
        setIsUploadOpen(false);
        setIsUploadBtnClick(false);
        searchParams.delete("upload");
        setSearchParams(searchParams);
        if (result?.successful?.length > 1) {
          toast.success("All files uploaded");
        } else {
          toast.success("File uploaded");
        }
      })
      .on("progress", (data) => {
        setPercentage(data);
      })
      .on("upload", (data) => {
        setOpen(false);
        setIsUploadOpen(true);
      });
  }, [source, searchQueryPlaylist]);

  const urlInputValueRef = useRef(urlInputValue);

  useEffect(() => {
    urlInputValueRef.current = urlInputValue;
  }, [urlInputValue]);

  // Uppy import button click handler
  // When user import video from url then this function will call
  const handleImportFromUrl = useCallback(async () => {
    if (
      urlInputValueRef.current === "" ||
      urlInputValueRef.current === undefined
    ) {
      // check url is valid or not
      toast.error("Please enter valid URL");
    } else {
      let import_button = document.getElementById("uppy_import_button");
      import_button.disabled = true;
      import_button.innerHTML = "Importing...";
      import_button.style.backgroundColor = "#79aeec"; // change disabled color
      import_button.style.color = "#fff";
      import_button.disabled = true;

      const urlParts = urlInputValueRef?.current?.split("/");
      const extractedText = urlParts[urlParts?.length - 1];
      const filenameWithoutExtension = extractedText?.replace(/\.[^/.]+$/, "");

      setTimeout(async () => {
        let payload = JSON.stringify({
          input: urlInputValueRef.current,
          collection_id: source,
          title: filenameWithoutExtension,
        });

        if (searchQueryPlaylist) {
          payload = JSON.stringify({
            ...JSON.parse(payload),
            playlist_id: searchQueryPlaylist,
            title: filenameWithoutExtension,
          });
        }

        try {
          let data = await http({}).post(
            endpoints.video.asset.createAsset,
            payload
          );
          if (data) {
            toast.success("URL Imported");
            customTrack("Upload Source", {
              source: "Link",
            });
            setOpenUppy(false);
            customTrack("Upload Source", {
              source: "Link",
            });
          }
        } catch (error) {
          import_button.disabled = false;
          import_button.innerHTML = "Import";
          import_button.style.backgroundColor = "#2275d7"; // change disabled color
          import_button.style.color = "#fff";
          toast.error(error?.response?.data?.error?.message);
        }
      }, 3000);
    }
  }, [source, searchQueryPlaylist, setOpenUppy]);

  const handleCancel = useCallback(() => {
    const customDiv = document.getElementById(
      "uppy-DashboardContent-panel--Url"
    );
    if (customDiv) {
      customDiv.remove();
    }
  }, []);

  const handleClick = useCallback(() => {
    const innerWrapElements = document.getElementsByClassName(
      "uppy-Dashboard-innerWrap"
    );

    if (innerWrapElements.length > 0) {
      const customDiv = document.createElement("div");
      customDiv.innerHTML = `
        <div class="uppy-DashboardContent-panel" role="tabpanel" data-uppy-paneltype="PickerPanel" id="uppy-DashboardContent-panel--Url">
          <div class="uppy-DashboardContent-bar">
            <div class="uppy-DashboardContent-title" role="heading" aria-level="1">Import from Link</div>
            <button class="uppy-DashboardContent-back" type="button">Cancel</button>
          </div>
          <div class="uppy-DashboardContent-panelBody">
            <div class="uppy-Url">
              <input class="uppy-u-reset uppy-c-textInput uppy-Url-input"  data-hj-allow="" type="text" aria-label="Enter URL to import a file" placeholder="Enter URL to import a file" data-uppy-super-focusable="true" form="kKRCH8x7UOtWYW76SibCT"
              >
              <button class="uppy-u-reset uppy-c-btn uppy-c-btn-primary uppy-Url-importButton" type="submit" form="kKRCH8x7UOtWYW76SibCT" id="uppy_import_button">Import</button>
            </div>
          </div>
        </div>
      `;

      const input = customDiv.querySelector(".uppy-Url-input");
      const importButton = customDiv.querySelector(".uppy-Url-importButton");
      const cancelButton = customDiv.querySelector(
        ".uppy-DashboardContent-back"
      );
      cancelButton.addEventListener("click", handleCancel);

      input.addEventListener("input", (e) => {
        setUrlInputValue((prevUrlInputValue) => e.target.value);
      });
      importButton.addEventListener("click", handleImportFromUrl);
      innerWrapElements[0].appendChild(customDiv);
    } else {
      console.error(
        "No elements found with the class name 'uppy-Dashboard-innerWrap'"
      );
    }
  }, [handleImportFromUrl, handleCancel, setUrlInputValue]);

  useEffect(() => {
    const addFilesListElements = document.getElementsByClassName(
      "uppy-Dashboard-AddFiles-list"
    );
    if (addFilesListElements.length > 0) {
      const customContainer = document.createElement("div");
      customContainer.innerHTML = `
    <div class="uppy-DashboardTab" role="presentation" data-uppy-acquirer-id="Url">
      <button type="button" class="uppy-u-reset uppy-c-btn uppy-DashboardTab-btn" role="tab" tabindex="0" data-cy="Url" aria-controls="uppy-DashboardContent-panel--Url" aria-selected="false" data-uppy-super-focusable="true">
        <div class="uppy-DashboardTab-inner">
          <svg aria-hidden="true" focusable="false" width="32" height="32" viewBox="0 0 32 32">
            <path d="M23.637 15.312l-2.474 2.464a3.582 3.582 0 01-.577.491c-.907.657-1.897.986-2.968.986a4.925 4.925 0 01-3.959-1.971c-.248-.329-.164-.902.165-1.149.33-.247.907-.164 1.155.164 1.072 1.478 3.133 1.724 4.618.656a.642.642 0 00.33-.328l2.473-2.463c1.238-1.313 1.238-3.366-.082-4.597a3.348 3.348 0 00-4.618 0l-1.402 1.395a.799.799 0 01-1.154 0 .79.79 0 010-1.15l1.402-1.394a4.843 4.843 0 016.843 0c2.062 1.805 2.144 5.007.248 6.896zm-8.081 5.664l-1.402 1.395a3.348 3.348 0 01-4.618 0c-1.319-1.23-1.319-3.365-.082-4.596l2.475-2.464.328-.328c.743-.492 1.567-.739 2.475-.657.906.165 1.648.574 2.143 1.314.248.329.825.411 1.155.165.33-.248.412-.822.165-1.15-.825-1.068-1.98-1.724-3.216-1.888-1.238-.247-2.556.082-3.628.902l-.495.493-2.474 2.464c-1.897 1.969-1.814 5.09.083 6.977.99.904 2.226 1.396 3.463 1.396s2.473-.492 3.463-1.395l1.402-1.396a.79.79 0 000-1.15c-.33-.328-.908-.41-1.237-.082z" fill="#FF753E" fill-rule="nonzero"></path>
          </svg>
        </div>
        <div class="uppy-DashboardTab-name">Link</div>
      </button>
    </div>
  `;

      const button = customContainer.querySelector("button");
      button.addEventListener("click", handleClick);

      addFilesListElements[0].appendChild(customContainer);
    } else {
      console.error(
        "No elements found with the class name 'uppy-Dashboard-AddFiles-list'"
      );
    }
  }, [handleClick, open]);

  useEffect(() => {
    if (percentage > 1 && percentage < 99) {
      const handleBeforeUnload = (event) => {
        // Display a confirmation dialog when the user tries to refresh the page
        event.preventDefault();
        event.returnValue = "";
      };

      // Add the beforeunload event listener
      window.addEventListener("beforeunload", handleBeforeUnload);

      // Cleanup the event listener when the component unmounts
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [percentage]);

  useEffect(() => {
    const button = document.querySelector(".uppy-DashboardContent-addMore");

    if (button) {
      if (source) {
        button.style.display = "";
      } else {
        button.style.display = "none";
      }
    }
  }, [source]);

  const showCloseBtn = () => {
    if (percentage === 0) {
      return (
        <button
          className={close_icon_class}
          onClick={() => {
            setOpenUppy(false);
            setIsUploadBtnClick(false);
          }}
        >
          <i className="bi bi-x-lg uppy-icon" />
        </button>
      );
    } else if (percentage === 100) {
      return (
        <button
          className={close_icon_class}
          onClick={() => {
            setOpenUppy(false);
            setIsUploadBtnClick(false);
          }}
        >
          <i className="bi bi-x-lg uppy-icon" />
        </button>
      );
    } else {
      return (
        <button className={close_icon_class} disabled>
          <i className="bi bi-x-lg uppy-icon" />
        </button>
      );
    }
  };

  const uploadText = () => {
    const extra_class = theme === "dark" ? "text-gray-900" : "text-white";
    const heading_text_class = `card-header-title py-2 text-lg ${extra_class}`;

    if ((percentage > 0 && percentage < 100) || isUploadOn) {
      return <h5 className={heading_text_class}>Uploading... {percentage}%</h5>;
    } else if (percentage === 100) {
      return <h5 className={heading_text_class}>Uploaded</h5>;
    } else {
      return <h5 className={heading_text_class}>Upload Video</h5>;
    }
  };

  return (
    <div
      className={`position-fixed mb-lg-6 mb-2 ${
        isUploadOn ? "bottom-0 start-40" : "uppy-modal-wrapper"
      }`}
      style={{ zIndex: 999 }}
    >
      <div className="row justify-content-lg-center">
        <div
          className={`card p-0 col-lg-4 col-12 ${
            !isUploadOn
              ? "custom-video-modal shadow-lg border-modal"
              : open
              ? "open-floating-custom-modal"
              : "close-floating-custom-modal"
          }`}
        >
          {isUploadOn ? (
            <div
              onMouseEnter={handleMouseEnter}
              className={`card-header card-header-content-between py-1 ${
                !open ? "rounded" : ""
              } ${theme === "dark" ? "bg-slate" : "bg-dark"}`}
              style={{
                border: !open ? "0px" : "",
              }}
            >
              {uploadText()}
              <div className="d-flex justify-content-center align-items-center">
                {open ? (
                  <button
                    className={chevron_icon_class}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <i className="bi bi-chevron-down uppy-icon" />
                  </button>
                ) : (
                  <button
                    className={chevron_icon_class}
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    <i className="bi bi-chevron-up uppy-icon" />
                  </button>
                )}
                {showCloseBtn()}
              </div>
            </div>
          ) : (
            <></>
          )}
          {open && (
            <div className="card-body p-2">
              <Dashboard
                uppy={uppy_config}
                showProgressDetails={false}
                theme="auto"
                hideUploadButton={true}
                inline={true}
                height={550}
                width={1200}
                proudlyDisplayPoweredByUppy={false}
                plugins={["Dropbox", "OneDrive"]}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
