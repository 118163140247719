import { routePaths } from "global/routePaths";

export const HORIZONTAL_ALIGN = [
  {
    value: "left",
    label: "left",
  },
  {
    value: "right",
    label: "right",
  },
];

export const VERTICAL_ALIGN = [
  {
    value: "top",
    label: "top",
  },
  {
    value: "bottom",
    label: "bottom",
  },
];

export const LANGUAGES = [
  { value: "aa", label: "Afar" },
  { value: "ab", label: "Abkhazian" },
  { value: "ae", label: "Avestan" },
  { value: "af", label: "Afrikaans" },
  { value: "ak", label: "Akan" },
  { value: "am", label: "Amharic" },
  { value: "an", label: "Aragonese" },
  { value: "ar", label: "Arabic" },
  { value: "as", label: "Assamese" },
  { value: "av", label: "Avaric" },
  { value: "ay", label: "Aymara" },
  { value: "az", label: "Azerbaijani" },
  { value: "ba", label: "Bashkir" },
  { value: "be", label: "Belarusian" },
  { value: "bg", label: "Bulgarian" },
  { value: "bh", label: "Bihari" },
  { value: "bi", label: "Bislama" },
  { value: "bm", label: "Bambara" },
  { value: "bn", label: "Bengali" },
  { value: "bo", label: "Tibetan" },
  { value: "br", label: "Breton" },
  { value: "bs", label: "Bosnian" },
  { value: "ca", label: "Catalan" },
  { value: "ce", label: "Chechen" },
  { value: "ch", label: "Chamorro" },
  { value: "co", label: "Corsican" },
  { value: "cr", label: "Cree" },
  { value: "cs", label: "Czech" },
  { value: "cu", label: "Church Slavonic" },
  { value: "cv", label: "Chuvash" },
  { value: "cy", label: "Welsh" },
  { value: "da", label: "Danish" },
  { value: "de", label: "German" },
  { value: "dv", label: "Divehi" },
  { value: "dz", label: "Dzongkha" },
  { value: "ee", label: "Ewe" },
  { value: "el", label: "Greek (Modern)" },
  { value: "en", label: "English" },
  { value: "eo", label: "Esperanto" },
  { value: "es", label: "Spanish" },
  { value: "et", label: "Estonian" },
  { value: "eu", label: "Basque" },
  { value: "fa", label: "Persian" },
  { value: "ff", label: "Fulah" },
  { value: "fi", label: "Finnish" },
  { value: "fj", label: "Fijian" },
  { value: "fo", label: "Faroese" },
  { value: "fr", label: "French" },
  { value: "fy", label: "Western Frisian" },
  { value: "ga", label: "Irish" },
  { value: "gd", label: "Gaelic" },
  { value: "gl", label: "Galician" },
  { value: "gn", label: "Guarani" },
  { value: "gu", label: "Gujarati" },
  { value: "gv", label: "Manx" },
  { value: "ha", label: "Hausa" },
  { value: "he", label: "Hebrew" },
  { value: "hi", label: "Hindi" },
  { value: "ho", label: "Hiri Motu" },
  { value: "hr", label: "Croatian" },
  { value: "ht", label: "Haitian" },
  { value: "hu", label: "Hungarian" },
  { value: "hy", label: "Armenian" },
  { value: "hz", label: "Herero" },
  { value: "ia", label: "Interlingua" },
  { value: "id", label: "Indonesian" },
  { value: "ie", label: "Interlingue" },
  { value: "ig", label: "Igbo" },
  { value: "ii", label: "Nuosu, Sichuan Yi" },
  { value: "ik", label: "Inupiaq" },
  { value: "io", label: "Ido" },
  { value: "is", label: "Icelandic" },
  { value: "it", label: "Italian" },
  { value: "iu", label: "Inuktitut" },
  { value: "ja", label: "Japanese" },
  { value: "jv", label: "Javanese" },
  { value: "ka", label: "Georgian" },
  { value: "kg", label: "Kongo" },
  { value: "ki", label: "Gikuyu" },
  { value: "kj", label: "Kwanyama" },
  { value: "kk", label: "Kazakh" },
  { value: "kl", label: "Kalaallisut" },
  { value: "km", label: "Central Khmer" },
  { value: "kn", label: "Kannada" },
  { value: "ko", label: "Korean" },
  { value: "kr", label: "Kanuri" },
  { value: "ks", label: "Kashmiri" },
  { value: "ku", label: "Kurdish" },
  { value: "kv", label: "Komi" },
  { value: "kw", label: "Cornish" },
  { value: "ky", label: "Kyrgyz" },
  { value: "la", label: "Latin" },
  { value: "lb", label: "Letzeburgesch" },
  { value: "lg", label: "Ganda" },
  { value: "li", label: "Limburgish" },
  { value: "ln", label: "Lingala" },
  { value: "lo", label: "Lao" },
  { value: "lt", label: "Lithuanian" },
  { value: "lu", label: "Luba-Katanga" },
  { value: "lv", label: "Latvian" },
  { value: "mg", label: "Malagasy" },
  { value: "mh", label: "Marshallese" },
  { value: "mi", label: "Maori" },
  { value: "mk", label: "Macedonian" },
  { value: "ml", label: "Malayalam" },
  { value: "mn", label: "Mongolian" },
  { value: "mr", label: "Marathi" },
  { value: "ms", label: "Malay" },
  { value: "mt", label: "Maltese" },
  { value: "my", label: "Burmese" },
  { value: "na", label: "Nauru" },
  { value: "nb", label: "Norwegian Bokmål" },
  { value: "nd", label: "Northern Ndebele" },
  { value: "ne", label: "Nepali" },
  { value: "ng", label: "Ndonga" },
  { value: "nl", label: "Dutch" },
  { value: "nn", label: "Norwegian Nynorsk" },
  { value: "no", label: "Norwegian" },
  { value: "nr", label: "South Ndebele" },
  { value: "nv", label: "Navajo" },
  { value: "ny", label: "Chichewa" },
  { value: "oc", label: "Occitan (post 1500)" },
  { value: "oj", label: "Ojibwa" },
  { value: "om", label: "Oromo" },
  { value: "or", label: "Oriya" },
  { value: "os", label: "Ossetian" },
  { value: "pa", label: "Punjabi" },
  { value: "pi", label: "Pali" },
  { value: "pl", label: "Polish" },
  { value: "ps", label: "Pashto" },
  { value: "pt", label: "Portuguese" },
  { value: "qu", label: "Quechua" },
  { value: "rm", label: "Romansh" },
  { value: "rn", label: "Rundi" },
  { value: "ro", label: "Romanian" },
  { value: "ru", label: "Russian" },
  { value: "rw", label: "Kinyarwanda" },
  { value: "sa", label: "Sanskrit" },
  { value: "sc", label: "Sardinian" },
  { value: "sd", label: "Sindhi" },
  { value: "se", label: "Northern Sami" },
  { value: "sg", label: "Sango" },
  { value: "si", label: "Sinhala" },
  { value: "sk", label: "Slovak" },
  { value: "sl", label: "Slovenian" },
  { value: "sm", label: "Samoan" },
  { value: "sn", label: "Shona" },
  { value: "so", label: "Somali" },
  { value: "sq", label: "Albanian" },
  { value: "sr", label: "Serbian" },
  { value: "ss", label: "Swati" },
  { value: "st", label: "Sotho, Southern" },
  { value: "su", label: "Sundanese" },
  { value: "sv", label: "Swedish" },
  { value: "sw", label: "Swahili" },
  { value: "ta", label: "Tamil" },
  { value: "te", label: "Telugu" },
  { value: "tg", label: "Tajik" },
  { value: "th", label: "Thai" },
  { value: "ti", label: "Tigrinya" },
  { value: "tk", label: "Turkmen" },
  { value: "tl", label: "Tagalog" },
  { value: "tn", label: "Tswana" },
  { value: "to", label: "Tonga (Tonga Islands)" },
  { value: "tr", label: "Turkish" },
  { value: "ts", label: "Tsonga" },
  { value: "tt", label: "Tatar" },
  { value: "tw", label: "Twi" },
  { value: "ty", label: "Tahitian" },
  { value: "ug", label: "Uighur" },
  { value: "uk", label: "Ukrainian" },
  { value: "ur", label: "Urdu" },
  { value: "uz", label: "Uzbek" },
  { value: "ve", label: "Venda" },
  { value: "vi", label: "Vietnamese" },
  { value: "vo", label: "Volap_k" },
  { value: "wa", label: "Walloon" },
  { value: "wo", label: "Wolof" },
  { value: "xh", label: "Xhosa" },
  { value: "yi", label: "Yiddish" },
  { value: "yo", label: "Yoruba" },
  { value: "za", label: "Zhuang" },
  { value: "zh", label: "Chinese" },
  { value: "zu", label: "Zulu" },
];

export const PROFILE_MENU = [
  {
    name: "Profile Details",
    icon: "bi-paperclip",
    activeLabel: "Video Collections",
    id: "profile_details",
    item_route: routePaths.video.profiles.editprofile,
  },
  {
    name: "Action",
    icon: "bi-trash3",
    activeLabel: "Profile Delete",
    id: "delete",
    item_route: routePaths.video.profiles.deleteprofile,
  },
];
