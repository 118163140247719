const images = {
  no_report: "https://assets.gumlet.io/public/img/new-onboarding/no_report.svg",
  no_collection: "https://assets.gumlet.io/public/img/new-onboarding/empty.svg",
  not_found_404: "https://assets.gumlet.io/public/img/errors/4xx.svg",
  not_authorized_401: "https://assets.gumlet.io/public/img/errors/4xx.svg",
  no_profile:
    "https://assets.gumlet.io/public/img/new-onboarding/no_profile.svg",
  no_video_light: "https://assets.gumlet.io/public/img/no-asset.svg",
  no_video_dark: "https://assets.gumlet.io/public/img/no_asset_dark(3).svg",
  no_thumbnail: "https://assets.gumlet.io/public/img/no_thumb_dark.svg",
  //-'no_thumbnail_light':"https://assets.gumlet.io/public/img/no_thumbnail_light_2.svg",
  no_folder: "https://assets.gumlet.io/public/img/no_folder.svg",
  no_folder_light: "https://assets.gumlet.io/public/img/no_folder_light.svg",
  something_went_wrong:
    "https://assets.gumlet.io/public/img/something_went_wrong.png",
  empty_box: "https://assets.gumlet.io/public/img/empty-box.png",
  server_error: "https://assets.gumlet.io/public/img/errors/5xx.svg",
  small_thubnail: "https://assets.gumlet.io/public/img/small_thumbnail.svg",
  error_thub: "https://assets.gumlet.io/public/img/error_dark_thumb.svg",
  no_thumbnail_light: "https://assets.gumlet.io/public/img/no_thumb_3.svg",
  error_thub_light: "https://assets.gumlet.io/public/img/error_light.svg",
  no_subuser:
    "https://assets.gumlet.io/public/img/new-onboarding/no_user_2.svg",
  player_placeholder:
    "https://assets.gumlet.io/public/img/placeholder-video.jpeg",

  // email asset
  generic: "https://assets.gumlet.io/public/img/email_provider/generic.svg",
  gmail: "https://assets.gumlet.io/public/img/email_provider/gmail.svg",
  activeCampaign:
    "https://assets.gumlet.io/public/img/email_provider/ActiveCampaign.svg",
  constantContact:
    "https://assets.gumlet.io/public/img/email_provider/Constant Contact.svg",
  getResponse:
    "https://assets.gumlet.io/public/img/email_provider/GetResponse.svg",
  campaignMonitor:
    "https://assets.gumlet.io/public/img/email_provider/campaign-monitor.svg",
  hubspot: "https://assets.gumlet.io/public/img/email_provider/hubspot.svg",
  klaviyoDark:
    "https://assets.gumlet.io/public/img/email_provider/klaviyo-dark.svg",
  klaviyo: "https://assets.gumlet.io/public/img/email_provider/klaviyo.svg",
  mailchimp: "https://assets.gumlet.io/public/img/email_provider/mailchimp.svg",
  marketo: "https://assets.gumlet.io/public/img/email_provider/marketo.svg",
  salesforce:
    "https://assets.gumlet.io/public/img/email_provider/salesforce.svg",
};

export default images;
